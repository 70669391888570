/*
FOR LAYOUT REFERENCE: https://dribbble.com/shots/23241286-Software-company-website

Global Colors:
orange: #FF7900
black: #000000
white: #ffffff
*/

@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.jost-<uniquifier> {
    font-family: "Jost", sans-serif;
    font-optical-sizing: auto;
    font-weight: <weight>;
    font-style: normal;
} */

* {
    padding: 0;
    margin: 0;
    font-family: "jost", sans-serif;
}

body {
    /* PRIMARY COLORS */
    --page-background: #1D2023;
    --primary-color: #84A4FC;
    --primary-text: #CCD0D8;
    --secondary-text: #84A4FC;
    --active-text: #84A4FC;
    --headings: #84A4FC;
    --borders: #84A4FC;
    --hover: #84A4FC;

    /* Mint Green Colors */
    /* --page-background: #000000;
    --primary-color: rgb(147, 233, 190);
    --primary-text: white;
    --secondary-text: #c8d7e3;
    --active-text: #93E9BE; */
    background: radial-gradient(#15232c, var(--page-background));
    background-color: var(--page-background);
}

#main-container {
    min-width: 450px;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#header {
    color: var(--primary-text);
    width: 100vw;
    height: 80px;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: radial-gradient(#0b1419, var(--page-background));
    opacity: .98;
}

#header-container {
    height: 80px;
    width: 90%;
    min-width: 500px;
    position: relative;
    display: flex;
    align-items: center;
}

#header-logo {
    max-width: 25%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
}

#header-nav {
    min-width: 550px;
    width: 100%;
    color: var(--primary-text);
    display: flex;
    align-items: center;
    justify-content: center;
}

#header-nav ul {
    margin: 0;
    padding: 0;
}

#header-nav li {
    font-size: 1em;
    display: inline;
    padding: 1.5em;
}

#header-nav li:hover {
    color: var(--hover);
    text-decoration: underline;
    text-underline-offset: 8px;
    cursor: pointer;
}

.active {
    color: var(--active-text);
    text-decoration: underline;
    text-underline-offset: 8px;
}

.slide-container {
    width: 100%;
    height: max-content;
    color: var(--primary-text);
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

#about {
    width: 90%;
    min-width: 500px;
    height: 600px;
    border: 2px solid rgba(133, 203, 246, .2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

#about h1 {
    color: var(--active-text);
    font-size: 2.3em;
    font-weight: normal;
    margin-bottom: 15px;
    text-align: center;
}

#about p {
    text-align: center;
    font-size: 1.3em;
    width: 65%;
    min-width: 450px;
    max-width: 800px;
    margin-bottom: 15px;
}


/************/
/* PROJECTS */
/************/

#projects {
    width: 90%;
    min-width: 500px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
}

#project-logo {
    width: 50%;
    min-width: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

#project-container {
    min-width: 480px;
    width: 80%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;
}

#project-container p {
    min-width: 400px;
    max-width: 60%;
    color: white;
}

#project-container p {
    width: 550px;
    color: white;
    text-align: center;
}

#project-container a {
    color: var(--primary-color);
    text-decoration: none;
}

#project-container a:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
}

/******************************/

#contact {
    width: 90%;
    min-width: 500px;
    height: max-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 60px 0;
    margin-top: 80px;
}

#contact-left-column {
    width: 40%;
    padding: 40px;
}

#contact-left-column h1 {
    font-size: 3.2em;
    color: var(--headings);
    margin-bottom: 10px;
}

#contact-left-column p {
    color: white;
    text-align: left;
    line-height: 175%;
}


#contact form {
    min-width: 400px;
}

form label {
    display: block;
    margin-bottom: 5px;
}

form input {
    color: white;
    width: 90%;
    max-width: 500px;
    padding: 10px;
    margin-bottom: 25px;
    border-radius: 5px;
    display: block;
    background-color: transparent;
    border: 1px solid var(--borders);
}

form textarea {
    color: white;
    width: 90%;
    height: 150px;
    padding: 10px;
    margin-bottom: 35px;
    border-radius: 5px;
    display: block;
    background-color: transparent;
    border: 1px solid var(--borders);
    resize: none;
}

#submit-btn {
    font-size: 1em;
    color: black;
    width: 95%;
    background-color: var(--borders);
    border: 1px solid var(--borders);
}

#submit-btn:hover {
    color: white;
    width: 95%;
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    cursor: pointer;
}


/******************************/

#footer-container {
    width: 100vw;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer-container p {
    color: var(--primary-text);
    font-size: 1em;
}